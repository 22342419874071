@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.colors.light.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.colors.dark.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.colors.blueprint.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.sizes.desktop.less";
@import "../../../node_modules/@is24/cosma-design-tokens/build/less/variables.sizes.palm.less";

:root {
	// COLOR VARIABLES
	.colors-light();

	[data-color-scheme="light"] {
		.colors-light();
	}

	[data-color-scheme="dark"] {
		.colors-dark();
	}

	[data-color-scheme="blueprint"] {
		.colors-blueprint();
	}

	// SIZE VARIABLES
	.sizes-palm();

	@media @mediaQueryLapAndUp {
		.sizes-desktop();
	}

	// TODO: replace with actual values
	--font-size-hint: @palmFontSize[hint];
	--font-size-paragraph: @palmFontSize[paragraph];
	--font-size-paragraph-tabular: @palmFontSize[paragraphTabular];
	--font-size-heading-0: @palmFontSize[heading0];
	--font-size-heading-1: @palmFontSize[heading1];
	--font-size-heading-2: @palmFontSize[heading2];
	--font-size-heading-3: @palmFontSize[heading3];
	--font-size-heading-4: @palmFontSize[heading4];
	--font-size-heading-5: @palmFontSize[heading5];

	--line-height-hint: @palmLineHeight[hint];
	--line-height-paragraph: @palmLineHeight[paragraph];
	--line-height-paragraph-tabular: @palmLineHeight[paragraphTabular];
	--line-height-heading-0: @palmLineHeight[heading0];
	--line-height-heading-1: @palmLineHeight[heading1];
	--line-height-heading-2: @palmLineHeight[heading2];
	--line-height-heading-3: @palmLineHeight[heading3];
	--line-height-heading-4: @palmLineHeight[heading4];
	--line-height-heading-5: @palmLineHeight[heading5];

	@media @mediaQueryLapAndUp {
		--font-size-hint: @lapAndUpFontSize[hint];
		--font-size-paragraph: @lapAndUpFontSize[paragraph];
		--font-size-paragraph-tabular: @lapAndUpFontSize[paragraphTabular];
		--font-size-heading-0: @lapAndUpFontSize[heading0];
		--font-size-heading-1: @lapAndUpFontSize[heading1];
		--font-size-heading-2: @lapAndUpFontSize[heading2];
		--font-size-heading-3: @lapAndUpFontSize[heading3];
		--font-size-heading-4: @lapAndUpFontSize[heading4];
		--font-size-heading-5: @lapAndUpFontSize[heading5];

		--line-height-hint: @lapAndUpLineHeight[hint];
		--line-height-paragraph: @lapAndUpLineHeight[paragraph];
		--line-height-paragraph-tabular: @lapAndUpLineHeight[paragraphTabular];
		--line-height-heading-0: @lapAndUpLineHeight[heading0];
		--line-height-heading-1: @lapAndUpLineHeight[heading1];
		--line-height-heading-2: @lapAndUpLineHeight[heading2];
		--line-height-heading-3: @lapAndUpLineHeight[heading3];
		--line-height-heading-4: @lapAndUpLineHeight[heading4];
		--line-height-heading-5: @lapAndUpLineHeight[heading5];
	}
}
